<template>
  <div
    class="flex flex-col items-center justify-center space-y-2 px-4 py-8"
  >
    <p class="text-lg">
      Spacer Block
    </p>
    <p class="text-sm text-gray-700">
      Spacing is set at {{ content.spacing ? `${content.spacing}px` : 'an invalid value' }}.
    </p>
  </div>
</template>
<script>
export default {
  friendlyName: 'Spacer Block',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content
    }
  }
}
</script>
